import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import carregando from '../../components/Geral/loading.gif';
import '../secretaria/Secretaria.css'
import {base_url, midia_url} from '../../environment'

import {RiWhatsappFill} from "react-icons/ri"
import {MdWatchLater} from "react-icons/md"
import {FaPhoneSquareAlt} from "react-icons/fa"
import {ImHome3} from "react-icons/im"
import { useParams } from "react-router-dom";

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"
var linkAcaoPadrao = "_blank", idSecretaria;

function Secretaria(){

    const [sobre, setsobre] = useState('');
    const [nome, setnome] = useState('');
    const [titulo, settitulo] = useState('');
    const [logo, setlogo] = useState('');

    const [whatsapp, setwhatsapp] = useState('');
    const [horario, sethorario] = useState('');
    const [fone, setfone] = useState('');
    const [endereco, setendereco] = useState('');

    const [secretario, setsecretario] = useState('');
    const [foto, setfoto] = useState('');
    
    const {slug} = useParams();

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }  

    // pegar dados da secretaria e noticias
    useEffect(() => {
        fetch(`${base_url}/public/orgaos/${slug}`)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados); 
            //console.log(dados.statusCode); 
            
            
            // eslint-disable-next-line
            if(dados.statusCode == 404){window.location = "/*";}

            idSecretaria = dados.id;
            //console.log(idSecretaria); 

            setsobre(dados.sobre || "(Não informado)");
            setnome(dados.nome || "(Não informado)");
            settitulo(dados.sigla || "(Não informado)");
            setlogo(midia_url+dados.logo || "(Não informado)");
            
            setfone(dados.fone || "(Não informado)");
            setendereco(dados.endereco || "(Não informado)");
            
            setwhatsapp(dados.celular || "(Não informado)");
            //mudar clasname para ButtonC quando tiver dados"
            sethorario(dados.expediente || "(Não informado)");

            setsecretario(dados.Responsavel.nome || "(Não informado)");  
            setfoto(midia_url+dados.Responsavel.foto || "(Não informado)");  

            document.getElementById("sobre").innerHTML = "Sobre"; 
        });

        // pegar dados das noticias
        // resolução pra o segundo useEffect 'aguardar' o idSecretaria

        if(idSecretaria != null)
        {
            fetch(base_url+"/public/noticias?orgao_id="+idSecretaria)
            .then((d) => d.json())
            .then(dados => {      
            //console.log(dados); 

                var arrayInicial = [];
                var origemNoticia = '#';
                //var mostraSecretaria;
                
                for(let i=0; i<4; i++){

                    if(dados[i] == null)
                    {
                        break;
                    }
                    
                    if(dados[i].Orgao != null)
                    {
                        origemNoticia = "/noticia?slug="+dados[i].slug+"&orgao_id="+dados[i].Orgao.id;
                        var mostraSecretaria = nome.substring(13);
                    }
                    else
                    {
                        origemNoticia = "noticia?slug="+dados[i].slug;
                    }
                    
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<a target='+linkAcaoPadrao+ ' href='+origemNoticia+'><button><img src='+midia_url+dados[i].capa+' alt="Noticia"/><p>'+dados[i].titulo+': '+dados[i].subtitulo+'</p><p>'+mostraSecretaria+'</p></button></a>')
                    );
                }
                
                document.getElementById("noticiaSecretarias").innerHTML = arrayInicial;     
                //console.log(arrayInicial);     
                // clicar no inicial quando terminar de carregar
                document.getElementById(5).click();          
            }); 
        }        
    });   

    function menuSec(id) {
        for(let i=5; i<=8; i++){
            document.getElementById(i).style = null;
        }
        document.getElementById(id).style = optionClicado;       
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if(id != 5)
        {
            document.getElementById("noticiaSecretarias").setAttribute('hidden', '');
        }
        else
        {
            document.getElementById("noticiaSecretarias").removeAttribute('hidden');
            document.getElementById("unidades").setAttribute('hidden', '');
        }

        // eslint-disable-next-line
        if(id != 6){
            document.getElementById("unidades").setAttribute('hidden', '');
            //document.getElementById("unidades").style = "display: none;";
        }
        else{
            document.getElementById("noticiaSecretarias").setAttribute('hidden', '');
            document.getElementById("unidades").removeAttribute('hidden');
            //document.getElementById("unidades").style = "display: flex;";
        }

        // eslint-disable-next-line
        //if(id == 8){window.location = "noticias";}
        // eslint-disable-next-line
        //if(id == 7){window.location = "galeria";}
    }  
     
    return(
        <>   
            <Header />
                <div className="pagina">
                    <div className="paginaInfo">
                        <div id='infos' className='infos'>
                            <button className='buttonA'>
                                <img src={logo} alt="Foto"></img>
                                <p>{nome}</p>
                                <p>{titulo}</p>
                            </button>

                            <div className='buttonD'>
                                <img src={foto} alt="Foto"></img>
                                <span>Secretário (a): <br></br>{secretario}</span>
                            </div>
                            
                            <div className='buttonC'>
                                <button className="icones" >< MdWatchLater /></button>
                                <span>{horario}</span>
                            </div>

                            <div className='buttonB'>
                                <button className="icones" >< RiWhatsappFill /></button>
                                <span>{whatsapp}</span>
                            </div>

                            <div className='buttonB'>
                                <button className="icones" >< FaPhoneSquareAlt /></button>
                                <span>{fone}</span>
                            </div>

                            <div className='buttonB'>
                                <button className="icones" >< ImHome3 /></button>
                                <span>{endereco}</span>
                            </div>
                            
                            
                        </div>

                        <div className="divSobre"> 
                            <p  id="sobre" className="sobre">Carregando...</p>
                            <p className="sobre">{sobre}</p>
                        </div>
                        
                    </div>   
                </div>
                <div className="menuSecretaria">
                    <li>
                        <button id="5" onClick={(e) => menuSec(e.target.id)}>Notícias da secretaria</button>
                        <button id="6" onClick={(e) => menuSec(e.target.id)}>Unidades</button>
                        <button id="7" onClick={(e) => menuSec(e.target.id)}>Legislação</button>
                        <button id="8" onClick={(e) => menuSec(e.target.id)}>Carta de serviços</button>
                    </li>  

                    <div className="noticiaSecretarias" >
                        <div id="noticiaSecretarias">{loading()}</div>
                    </div>

                    <div className="unidades" >
                        <div id="unidades">TESTE</div>
                    </div>
                </div>
            <Footer/>
        </>   
    );
}

export default Secretaria;
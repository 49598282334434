import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import Carrousel from "../../components/Galeria/Galeria"
import '../municipio/municipio.css'
import prefeita from '../../img/prefeitos/Cinthia_Ribeiro_em_maio_de_2019_1-768x513.jpg'
import {FaMapMarkerAlt,FaArrowUp} from "react-icons/fa"
import CarrouselSimbolo from "./components/CarrouselSimbolo";
import { useEffect, useState } from "react";
import carregando from '../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../environment'

const imagens = [{original: carregando,},];


function Municipio(){
   
    let caminhoImg = midia_url;
    const [municipio, setMuniciopio] = useState({})
    const [date, setDate] = useState('')
    const [populacao, setPopulacao] = useState(0)

    useEffect (() =>{
        fetch(`${base_url}/public/municipio`)
        .then( resultInfo => resultInfo.json())
        .then( dados => {
           
            let date = new Date(dados.data_aniversario);
            let dataformatada = date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
            console.log(dataformatada.toString())
            let populacaoFormatada = dados.populacao.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");             
            
           
            imagens.pop();       
            for(let i=0; i<dados.Galeria.Imagem.length; i++){   
                imagens.push({original: caminhoImg+dados.Galeria.Imagem[i].imagem,},);
            }

            setMuniciopio(dados) 
            setDate(dataformatada);
            setPopulacao(populacaoFormatada)
                        
          
        })               
    })

      

    return(
        <div >
            <Header />
            <div className="container-municipio">

            <div className="municipio">
                <div id="sobre" className="sobre">

                  
                    <div className="container">                    
                        <h2>Sobre</h2>
                        <p>{municipio.sobre}</p>
                         <img src={`${caminhoImg}${municipio.Galeria?.capa}`} />
                    </div>
                                                 

                </div>
                
                <div id="historia" className="historia">
                    <div className="container">
                        <h2>Historia</h2>
                       <p>{municipio.historia}</p>
                    </div>
                    <div className="carrousel">        
                        {  
                            <Carrousel image={imagens} />
                        }
                                                                         
                    </div>
                </div>

                <div id="hino" className="hino">
               
                    <div className="container">
                    <h2>Hino</h2>
                        <div className="text-hino">
                          
                        <p> {municipio.hino}</p>
                        <p> {municipio.hino}</p>
                       
                            
                        </div>
                        
                    </div>                   
                </div>
                
                <div id="simbolos" className="simbolos">
                    <div className="container">
                    <h2>Simbolos</h2>
                        <div className="cardImg">
                        { municipio.Simbolo?.map( item => (
                            <CarrouselSimbolo img={item.imagem} />
                        ))
                        }
                                             
                        </div>
                    </div>
                </div>

                <div id="pontosTuristicos" className="pontosTuristicos">
                    <div className="container">
                       
                      
                        <div class="mapouter">
                        <div className="info">
                            <h2>Pontos turisticos  da cidade de Palmas</h2>
                            <button ><FaMapMarkerAlt/> <span>VER PONTOS</span></button>
                        </div>
                            <div class="gmap_canvas">
                                <iframe   src="https://maps.google.com/maps?q=ookatecnologia&t=&z=10&       ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                </iframe>
                               
                            </div>
                        </div>
                </div>
            </div>
                   
            </div>

                <div className="menu-municipio">
                    <div className="container-prefeita">
                        <div className="bg-prefeita">
                            <div className="circle">
                                 <img src={prefeita}/>
                            </div>
                            
                            <div className="bg-text">
                                <h2>Prefeita Municipal</h2>
                                <h3>Cithia Ribeiro</h3>                                           
                            </div>
                        </div>   
                        
                       
                    </div>

                    <ul className="menuItemInfo">
                                    <li><b> Getilíco:</b> {municipio.gentilico}</li>
                                    <li><b>População:</b> {populacao} </li>
                                    <li><b> Area:</b> {municipio.area} km²</li>
                                    <li><b>Data de aniversário:</b> {date}</li>
                            </ul>
                            <ul className="menuItem">
                                   <a href="#sobre"><li>Sobre</li></a>
                                   <a href="#historia"><li>Historia</li></a>
                                   <a href="#hino"><li>Hino</li></a>
                                   <a href="#simbolos"><li>Simbolos</li></a>
                                   <a href="#pontosTuristicos"><li>Pontos turisticos</li></a> 
                            </ul>
                </div>
            </div>
            <a href="#sobre">
             <div className="link_top">
              
                    <FaArrowUp/>   
               
             </div>
            </a>    
            <Footer/>
        </div>
    );
}

export default Municipio;
import React, { useEffect} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import carregando from '../../components/Geral/loading.gif';
import '../noticias/Noticias.css'
import {base_url, midia_url} from '../../environment'

var urlGeral = base_url+'/public/noticias';
var urlSecretarias =  base_url+'/public/prefeitura/secretarias';
var caminhoGlobal = midia_url;
var linkAcaoPadrao = "_blank";

var qtdPaginas=1, page, inicio = 0, fim, urlPagina,  tamanhoFixo = 9, orgao_id;
var umaVez=true;
var unaVez=true;
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

var secretarias= [];

function Noticias(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }  
       
    const search = window.location.search;
    const params = new URLSearchParams(search);
    page = params.get('page');
    if(page == null)page = 1;

    inicio = tamanhoFixo * (page -1); 
    fim = tamanhoFixo * page;

    orgao_id = params.get('orgao_id');    
    localStorage.setItem("secSelecionada", orgao_id);

    if(orgao_id == null){
        urlPagina = String(urlGeral+"?&start="+inicio+"&size="+fim);
    }
    else
    {
        urlPagina = String(urlGeral+"?origin=http%3A%2F%2Flocalhost%3A3000&start="+inicio+"&size="+fim+"&orgao_id="+orgao_id);
    }
  
    // preencher botoes secretarias
    useEffect(() => {
        fetch(urlSecretarias)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 
        
            var qtdSecretarias = dados.length;  

            if(orgao_id == null)
            {
                document.getElementById('none').style = optionClicado;
            }
            
            if(unaVez)
            {
                for(let i=0; i<qtdSecretarias; i++){

                    //guarda dados das secretarias
                    secretarias.push(dados[i]);
                  
                    var soNome = dados[i].nome.substring(13)
                    const butin = document.createElement("button");  
                    butin.innerHTML = soNome;

                    // eslint-disable-next-line
                    if(orgao_id == dados[i].id)
                    {
                        butin.style = optionClicado;
                        document.getElementById('none').style = null;
                    }                   

                    const link = document.createElement("a");
                    link.href = String("noticias?orgao_id="+dados[i].id);
                    link.target = "_self";

                    link.appendChild(butin);
                    document.getElementById("secretarias").appendChild(link);
                }

                unaVez=false;
            }    
            
            
            //console.log(secretarias); 
                              
        });
    });

    // pegar dados das noticias (intervalo)
    useEffect(() => {
        fetch(urlPagina)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 

            var arrayInicial = [];
            var arrayIntermediario = [];
            var arrayDemais = [];
            var origemNoticia;
            var mostraSecretaria;
            
            for(let i=0; i<tamanhoFixo; i++){
                //console.log(dados[i].Orgao);

                if(dados[i] == null)
                {
                    if(i > 0)break;
                    window.location = "noticias";
                } 

                mostraSecretaria = null;
                
                if(dados[i].Orgao != null)
                {
                    origemNoticia = "noticia?slug="+dados[i].slug+"&orgao_id="+dados[i].Orgao.id;

                    var index = -1;
                    var val = String(dados[i].Orgao.id)
                    //console.log(val)
                    // eslint-disable-next-line 
                    secretarias.find(function(item, i){if(String(item.id) === val)index = i;});
                    mostraSecretaria = secretarias[index].nome.substring(13);
                    console.log(mostraSecretaria);
                }
                else
                {
                    origemNoticia = "noticia?slug="+dados[i].slug;
                    mostraSecretaria = "Geral";
                }

                if((inicio+i) === inicio)
                {
                    const link = document.createElement("a");
                    link.href = origemNoticia;
                    link.target = linkAcaoPadrao;

                    const node = document.createElement("button");      
                    node.className = "primeiroBotao";

                    const img = document.createElement("img");
                    img.src = caminhoGlobal+dados[i].capa;
                    img.alt = "Noticia";

                    const paragrafo = document.createElement("p");
                    paragrafo.innerHTML = dados[i].titulo+': '+dados[i].subtitulo;       
                    
                    const subParagrafo = document.createElement("p");
                    subParagrafo.innerHTML = mostraSecretaria;
                                         
                    link.appendChild(node);
                    node.appendChild(img);
                    node.appendChild(paragrafo);    
                    node.appendChild(subParagrafo);     

                    const list = document.getElementById("destaque");
                    list.removeChild(list.lastElementChild);

                    document.getElementById("destaque").appendChild(link);    
    
                }
                else if(inicio+i < inicio+5)
                {
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<a target='+linkAcaoPadrao+ ' href='+origemNoticia+'><button><img src='+caminhoGlobal+dados[i].capa+' alt="Noticia"/><p>'+dados[i].titulo+': '+dados[i].subtitulo+'</p><p>'+mostraSecretaria+'</p></button></a>')
                    );
                }

                if(inicio+i >= inicio+3)
                {
                    arrayDemais.push(
                        // eslint-disable-next-line
                        String('<a target='+linkAcaoPadrao+ ' href='+origemNoticia+'><button><img src='+caminhoGlobal+dados[i].capa+' alt="Noticia"/><p>'+dados[i].titulo+': '+dados[i].subtitulo+'</p><p>'+mostraSecretaria+'</p></button></a>')
                    );
                }

                if(inicio+i >= inicio+5)
                {
                    arrayIntermediario.push(
                        // eslint-disable-next-line
                        String('<a target='+linkAcaoPadrao+ ' href='+origemNoticia+'><button><img src='+caminhoGlobal+dados[i].capa+' alt="Noticia"/><p>'+dados[i].titulo+': '+dados[i].subtitulo+'</p><p>'+mostraSecretaria+'</p></button></a>')
                    );
                }               

                
                linkAcaoPadrao = "_blank";
            }
            
            document.getElementById("inicial").innerHTML = arrayInicial;    
            document.getElementById("intermediario").innerHTML = arrayIntermediario;    
            document.getElementById("demais").innerHTML = arrayDemais;                   
        });
    });
    

    // verificar quantas páginas tem
    useEffect(() => {

        if(orgao_id == null){
            fetch(urlGeral)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 

                var extrapolou = dados.length % tamanhoFixo;
                qtdPaginas = (dados.length - extrapolou) / tamanhoFixo;
                // eslint-disable-next-line
                if(extrapolou != 0) qtdPaginas = qtdPaginas + 1;
                //console.log(qtdPaginas);     
                
                if(umaVez)
                {
                    for(let i=1; i<=qtdPaginas; i++){

                        const index = document.createElement("a");
                        index.href = "noticias?page="+i;
                        const butao = document.createElement("button");
                        butao.innerHTML = i;

                        index.appendChild(butao);
                        document.getElementById("indexNoticias").appendChild(index);   
                    }

                    umaVez=false;
                }
                
                document.getElementById("ultimaPagina").href = 'noticias?page='+ qtdPaginas;
                    
            });
        }
        else
        {
            fetch(urlPagina)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 

                var extrapolou = dados.length % tamanhoFixo;
                qtdPaginas = (dados.length - extrapolou) / tamanhoFixo;
                // eslint-disable-next-line
                if(extrapolou != 0) qtdPaginas = qtdPaginas + 1;
                //console.log(qtdPaginas);     
                
                if(umaVez)
                {
                    for(let i=1; i<=qtdPaginas; i++){

                        const index = document.createElement("a");
                        index.href = "noticias?page="+i+"&orgao_id="+orgao_id;
                        const butao = document.createElement("button");
                        butao.innerHTML = i;

                        index.appendChild(butao);
                        document.getElementById("indexNoticias").appendChild(index);   
                    }

                    umaVez=false;
                }
                
                document.getElementById("ultimaPagina").href = 'noticias?page='+ qtdPaginas +"&orgao_id="+orgao_id;
                document.getElementById("primeiraPagina").href = 'noticias?page=1&orgao_id='+orgao_id;  
            });
        }        
    });

    // pular dez apenas se tiver páginas suficientes
    if((qtdPaginas-page) > 10){
        document.getElementById("menos10").href = 'noticias?page='+ (page - 10);
        document.getElementById("mais10").href = 'noticias?page='+ (page + 10);
    } 

    // ajusta os botoes pelo tamanho da tela somente a primeira vez
    useEffect(() => {
        
        if(window.innerWidth < 1291)
        {
            document.getElementById("demais").setAttribute('hidden', '');
            document.getElementById("intermediario").removeAttribute('hidden');
        }
        else if(window.innerWidth < 1784)
        {
            document.getElementById("demais").removeAttribute('hidden');
            document.getElementById("intermediario").setAttribute('hidden', '');
        }
        else
        {
            document.getElementById("demais").setAttribute('hidden', '');
            document.getElementById("intermediario").removeAttribute('hidden');
        }

        if(window.innerWidth < 760)
        {
            document.getElementById("pagNoticias").innerHTML = String("Filtrar notícias da secretaria de: ( &larr; para mais)");
        }        

    });    

    return(
        <>   
            <Header />
                <p id="pagNoticias" className="pagNoticias">Filtrar notícias da secretaria de: </p>
                <div className="secretarias">
                    <div id="secretarias" >
                        <a href="noticias" target="_self"><button id="none">Todas</button></a>
                    </div>                    
                    
                    <div className="secretariasInfo" id="secretariasInfo">
                        <div id='destaque'>{loading()} </div>      
                        <div id='inicial' >{loading()}</div>
                    </div>   

                    <div className="secretariasInfo" id="secretariasInfo">
                        <div id='intermediario' >{loading()}</div>
                        <div id='demais' >{loading()}</div>
                    </div>   
                </div>

                <div className="menuNoticias">
                    <div id="indexNoticias"></div>
                    <div>
                        <a id="primeiraPagina" href="noticias" ><button >Primeira</button></a>
                        <a id="menos10" href="noticias" ><button >-10 páginas</button></a>
                        <a id="mais10" href="noticias" ><button >+10 páginas</button></a>
                        <a id="ultimaPagina" href="noticias" ><button >Última</button></a>
                    </div>
                </div>
            <Footer/>
        </>   
    );
}

export default Noticias;
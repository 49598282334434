import React, { useEffect } from "react";
import './options.css';
import '../../../components/Geral/geral.css';
import carregando from '../../../components/Geral/loading.gif';
import {base_url} from '../../../environment';

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"
var optionDesclicado = "background-color: var(--mainColor2); color: var(--textColor);"

var totalOptions = 4;
var cidadao = base_url+'/public/links/home/cidadao';
var servidor = base_url+'/public/links/home/servidor';
var empresa = base_url+'/public/links/home/empresa';
var turista = base_url+'/public/links/home/turista';

var linkAcaoPadrao = "_blank";

function Options() {

    function OptSelec(id) {
        for(let i=1; i<=totalOptions; i++){
            document.getElementById(i).style = optionDesclicado;
        }
        document.getElementById(id).style = optionClicado;      
        console.log("Selecionou o botão " + id); 

        switch (id) {
        case '1':
            document.getElementById("cidadao").removeAttribute('hidden');
            document.getElementById("servidor").setAttribute('hidden', '');
            document.getElementById("empresa").setAttribute('hidden', '');
            document.getElementById("turista").setAttribute('hidden', '');
            break;
        case '2':
            document.getElementById("cidadao").setAttribute('hidden', '');
            document.getElementById("servidor").removeAttribute('hidden');
            document.getElementById("empresa").setAttribute('hidden', '');
            document.getElementById("turista").setAttribute('hidden', '');
            break;
        case '3':
            document.getElementById("cidadao").setAttribute('hidden', '');
            document.getElementById("servidor").setAttribute('hidden', '');
            document.getElementById("empresa").removeAttribute('hidden');
            document.getElementById("turista").setAttribute('hidden', '');
            break;
        case '4':
            document.getElementById("cidadao").setAttribute('hidden', '');
            document.getElementById("servidor").setAttribute('hidden', '');
            document.getElementById("empresa").setAttribute('hidden', '');
            document.getElementById("turista").removeAttribute('hidden');
            break;
        default:
            document.getElementById("cidadao").removeAttribute('hidden');
            document.getElementById("servidor").setAttribute('hidden', '');
            document.getElementById("empresa").setAttribute('hidden', '');
            document.getElementById("turista").setAttribute('hidden', '');
        }

    }

    function loading() {
        return <div id='loading'><img src={carregando} alt="Logo da opção selecionada"/></div>;
    }

    // useEffect das opcoes cidadao
    useEffect(() => {
        fetch(cidadao)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 

            var arrayOptions = [];
            var urlFinal;
            
            for(let i=0; i<dados.length; i++){

                if(dados[i].link == null){
                    urlFinal = dados[i].uri;
                    linkAcaoPadrao = "_self";
                }
                else
                {urlFinal = dados[i].link;}
                               
                arrayOptions.push(
                    String('<a target='+linkAcaoPadrao+ ' href='+urlFinal+'><button><img src='+dados[i].icone+' alt="Logo da opção selecionada"/> <br>'+dados[i].titulo+' </button></a>')
                );

                linkAcaoPadrao = "_blank";
            }
            document.getElementById("cidadao").innerHTML = arrayOptions;                              
        });
    });

    // useEffect das opcoes servidor
    useEffect(() => {
        fetch(servidor)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 

            var arrayOptions = [];
            var urlFinal;
            
            for(let i=0; i<dados.length; i++){

                if(dados[i].link == null){
                    urlFinal = dados[i].uri;
                    linkAcaoPadrao = "_self";
                }
                else
                {urlFinal = dados[i].link;}
                               
                arrayOptions.push(
                    String('<a target='+linkAcaoPadrao+ ' href='+urlFinal+'><button><img src='+dados[i].icone+' alt="Logo da opção selecionada"/> <br>'+dados[i].titulo+' </button></a>')
                );

                linkAcaoPadrao = "_blank";
            }
            document.getElementById("servidor").innerHTML = arrayOptions;                              
        });
    });

     // useEffect das opcoes empresa
     useEffect(() => {
        fetch(empresa)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 

            var arrayOptions = [];
            var urlFinal;
            
            for(let i=0; i<dados.length; i++){

                if(dados[i].link == null){
                    urlFinal = dados[i].uri;
                    linkAcaoPadrao = "_self";
                }
                else
                {urlFinal = dados[i].link;}
                               
                arrayOptions.push(
                    String('<a target='+linkAcaoPadrao+ ' href='+urlFinal+'><button><img src='+dados[i].icone+' alt="Logo da opção selecionada"/> <br>'+dados[i].titulo+' </button></a>')
                );

                linkAcaoPadrao = "_blank";
            }
            document.getElementById("empresa").innerHTML = arrayOptions;                              
        });
    });
    
     // useEffect das opcoes turista
     useEffect(() => {
        fetch(turista)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 

            var arrayOptions = [];
            var urlFinal;
            
            for(let i=0; i<dados.length; i++){

                if(dados[i].link == null){
                    urlFinal = dados[i].uri;
                    linkAcaoPadrao = "_self";
                }
                else
                {urlFinal = dados[i].link;}
                               
                arrayOptions.push(
                    String('<a target='+linkAcaoPadrao+ ' href='+urlFinal+'><button><img src='+dados[i].icone+' alt="Logo da opção selecionada"/> <br>'+dados[i].titulo+' </button></a>')
                );

                linkAcaoPadrao = "_blank";
            }
            document.getElementById("turista").innerHTML = arrayOptions;                              
        });
    });

    return (
        <>       
            <div className="options">
                <div>
                    <button id="1" onClick={(e) => OptSelec(e.target.id)}>Cidadão</button>
                    <button id="2" onClick={(e) => OptSelec(e.target.id)}>Servidor</button>
                    <button id="3" onClick={(e) => OptSelec(e.target.id)}>Empresa</button>
                    <button id="4" onClick={(e) => OptSelec(e.target.id)}>Turista</button>
                </div>

                <div className="optionsInfo" id="optionsInfo">
                    <div id='cidadao' >{loading()}</div>
                    <div id='servidor' hidden>{loading()}</div>
                    <div id='empresa' hidden>{loading()}</div>
                    <div id='turista' hidden>{loading()}</div>
                </div>   
            </div>
        </>
  );
}

export default Options;
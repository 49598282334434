import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";

import Options from "./Options/options.js";
import Intermediario from "./Intermediario/intermediario.js";
import Noticias from "./Noticias/noticias.js";
import Local from "./Local/local.js";
import Servidores from './Servidores/servidores';


function Home() {
  return (
    <div className="App">
      <Header/>
      <Options/>
      <Intermediario/>
      <Noticias/>
      <Local/>
      <Servidores/>
      <Footer/>
    </div>
  );
}

export default Home;

import React, {useEffect, useRef} from "react";
import {FaTimes, FaBars} from "react-icons/fa"
import { Link } from "react-router-dom";
import './header.css';
import '../Geral/geral.css';

import lupa from './lupa.png';
import carregando from '../Geral/loading.gif';
import {base_url, midia_url} from '../../environment'

var bannerHeader = base_url+'/public/banners/header';
var url = base_url+'/public';
var caminhoGlobal = midia_url;

function Header() {

    // useEffect imagens banner. não recursivo porque se mudar a qtd precisa alterar manual no css
    useEffect(() => {
        fetch(bannerHeader)
        .then((d) => d.json())
        .then(dados => {            
            document.getElementById("banner1").src = caminhoGlobal+dados[0].imagem;    
            document.getElementById("banner2").src = caminhoGlobal+dados[1].imagem;    
            document.getElementById("banner3").src = caminhoGlobal+dados[2].imagem;    
            document.getElementById("banner4").src = caminhoGlobal+dados[3].imagem;    
        });
    });

    // useEffect imagem logo
    useEffect(() => {
        fetch(url)
        .then((d) => d.json())
        .then(dados => {            
            document.getElementById("logo1").src = caminhoGlobal+dados.logo; 
        });
    });
    
    const navRef = useRef();

    const showNavBar = () => {
        
        navRef.current.classList.toggle('responsive_nav');

        console.log(navRef)
    }

    const [pesquisa, setPesquisa] = React.useState("");

    function handleSubmit(event) {
        event.preventDefault();
        console.log("Digitou na pesquisa:" + pesquisa);
    }
 
    return (
       
        <>  
            <header>
                <nav ref={navRef} >
                    <Link to='/'> Inicio</Link>
                    <Link to='/'> Transparência</Link>
                    <Link to='/municipio'> Município</Link>
                    <Link to='/noticias'>Notícias</Link>
                    <Link to='/'>Secretaria</Link>
                    <Link to='/'>Carta de Serviços</Link>
                    <Link to='/'>Covid</Link>
                    <Link to='/'>Acessibilidade</Link>

                    <div className='admin'>
                        <Link to='/'>Webmail</Link>
                        <Link to='/'>Entrar</Link>
                    </div>

                    <button className='nav-btn nav-close-btn' onClick={showNavBar}>
                        <FaTimes /> 
                    </button>
                </nav>
                
                <button className='nav-btn'  onClick={showNavBar}>
                    <FaBars/>
                </button>
                            
                <button className='nav-btn'  onClick={showNavBar}>
                </button>
                
            </header>
        
            <form onSubmit={handleSubmit} className="pesquisa" method="post" encType="application/json" id="pesquisar">
                <input type="text" name="pesquisa" id="pesquisa" placeholder="Pesquise:" autoComplete="off"  onChange={(e) => setPesquisa(e.target.value)} ></input>
                {/* eslint-disable-next-line */}
                <a onClick={handleSubmit}><img src={lupa} alt="lupa" className="lupa"></img></a>
            </form>

            <div className="banner">
                <img id="banner1" alt="banner1" src={carregando} />
                <img id="banner2" alt="banner2" src={carregando} />
                <img id="banner3" alt="banner3" src={carregando} />
                <img id="banner4" alt="banner4" src={carregando} />
            </div>

            <div className="logo1">
                <img id="logo1" alt="logo1" src={carregando}></img>
            </div>
        </>
        
    );
   
}

export default Header;
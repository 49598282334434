import React, { useEffect, useState } from "react";
import './noticias.css';
import '../../../components/Geral/geral.css';
import carregando from '../../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../../environment';

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"
var optionDesclicado = "background-color: var(--mainColor2); color: var(--textColor);"

var url = base_url+'/public/noticias';
var urlVideos = base_url+'/public/videos';
var caminhoGlobal = midia_url;

var video1;
var video2;
var video3;
var video4;

var umavez = true;

function Noticias() {

    //por algum motivo, estava vindo clicado quando coloquei <a> no "todas as noticias"    
    //document.getElementById("8").style = optionDesclicado;    

    const [Noticia1, setNoticia1] = useState('')
    const [Noticia2, setNoticia2] = useState('')
    const [Noticia3, setNoticia3] = useState('')
    const [Noticia4, setNoticia4] = useState('')
    const [Noticia5, setNoticia5] = useState('')
    const [Noticia6, setNoticia6] = useState('')
    const [Noticia7, setNoticia7] = useState('')

    function loading() {
        return <div id='loading'><img src={carregando} alt="Logo da opção selecionada"/></div>;
    }

   // useEffect das notícias
    useEffect(() => {
        fetch(url)
        .then((d) => d.json())
        .then(dados => {

            setNoticia1({
                slug: "noticias/"+dados[0].slug,
                titulo: dados[0].titulo,
                subtitulo: dados[0].subtitulo,
                capa: caminhoGlobal+dados[0].capa,
                data: dados[0].data,
            }); 
            setNoticia2({
                slug: "noticias/"+dados[1].slug,
                titulo: dados[1].titulo,
                subtitulo: dados[1].subtitulo,
                capa: caminhoGlobal+dados[1].capa,
                data: dados[1].data,
            }); 
            setNoticia3({
                slug: "noticias/"+dados[2].slug,
                titulo: dados[2].titulo,
                subtitulo: dados[2].subtitulo,
                capa: caminhoGlobal+dados[2].capa,
                data: dados[2].data,
            }); 
            setNoticia4({
                slug: "noticias/"+dados[3].slug,
                titulo: dados[3].titulo,
                subtitulo: dados[3].subtitulo,
                capa: caminhoGlobal+dados[3].capa,
                data: dados[3].data,
            }); 
            setNoticia5({
                slug: "noticias/"+dados[4].slug,
                titulo: dados[4].titulo,
                subtitulo: dados[4].subtitulo,
                capa: caminhoGlobal+dados[4].capa,
                data: dados[4].data,
            }); 
            setNoticia6({
                slug: "noticias/"+dados[5].slug,
                titulo: dados[5].titulo,
                subtitulo: dados[5].subtitulo,
                capa: caminhoGlobal+dados[5].capa,
                data: dados[5].data,
            });             
            setNoticia7({
                slug: "noticias/"+dados[6].slug,
                titulo: dados[6].titulo,
                subtitulo: dados[6].subtitulo,
                capa: caminhoGlobal+dados[6].capa,
                data: dados[6].data,
            });   
            
            document.getElementById("loading").innerHTML = "";

            // para não aparecer a imagem quebrada (erro ao carregar direto na aba videos.)
            if(umavez)
            {
                document.getElementById("noticiasInfo").removeAttribute('hidden');
                umavez = false;
            }

            
        });
    });

    // useEffect dos videos - não fiz os videos automatizados porque o iframe não carrega
    useEffect(() => {
        fetch(urlVideos)
        .then((d) => d.json())
        .then(dados => {
            video1 = 'https://www.youtube.com/embed/' + dados[0].link + '?controls=0?rel=0?';
            video2 = 'https://www.youtube.com/embed/' + dados[1].link + '?controls=0?rel=0?';
            video3 = 'https://www.youtube.com/embed/' + dados[2].link + '?controls=0?rel=0?';
            video4 = 'https://www.youtube.com/embed/' + dados[3].link + '?controls=0?rel=0?';
        });
    });

    function OptSelec(id) {
        for(let i=5; i<=8; i++){
            document.getElementById(i).style = optionDesclicado;
        }
        document.getElementById(id).style = optionClicado;       
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if(id != 5)
        {
            document.getElementById("noticiasInfo").setAttribute('hidden', '');
        }
        else
        {
            document.getElementById("noticiasInfo").removeAttribute('hidden');
            document.getElementById("videos").setAttribute('hidden', '');
        }

        // eslint-disable-next-line
        if(id != 6){
            document.getElementById("videos").setAttribute('hidden', '');
            document.getElementById("videos").style = "display: none;";
        }
        else{
            document.getElementById("videos").removeAttribute('hidden');
            document.getElementById("videos").style = "display: flex;";
        }

        //se maior que 8 é vídeos
        if(id > 8){
            document.getElementById("videos").style = "display: flex;";
            document.getElementById("6").style = optionClicado;    
        }

        // eslint-disable-next-line
        if(id == 8){window.location = "noticias";}
        // eslint-disable-next-line
        if(id == 7){window.location = "galeria";}

        // eslint-disable-next-line
        if(id == 9){document.getElementById("videoPrincipal").src = video1;}
        // eslint-disable-next-line
        if(id == 10){document.getElementById("videoPrincipal").src = video2;}
        // eslint-disable-next-line
        if(id == 11){document.getElementById("videoPrincipal").src = video3;}
        // eslint-disable-next-line
        if(id == 12){document.getElementById("videoPrincipal").src = video4;}

    }

    return (
        <>       
            <div className="noticias">
                <li>
                    <button id="5" onClick={(e) => OptSelec(e.target.id)}>Notícias recentes</button>
                    <button id="6" onClick={(e) => OptSelec(e.target.id)}>Vídeos</button>
                    <button id="7" onClick={(e) => OptSelec(e.target.id)}>Galerias</button>
                    <button id="8" onClick={(e) => OptSelec(e.target.id)}>Todas as notícias</button>
                </li>     

                {loading()}
                <div hidden className="noticiasInfo" id="noticiasInfo">
                    
                    <a href={Noticia1.slug}><button className="noticiaPequena1" ><img src={Noticia1.capa} alt="notícia"/><p>{Noticia1.titulo}: {Noticia1.subtitulo}</p></button></a>  
                    <a href={Noticia2.slug}><button className="noticiaPequena2" ><img src={Noticia2.capa} alt="notícia"/><p>{Noticia2.titulo}: {Noticia2.subtitulo}</p></button></a>  
                    <a href={Noticia3.slug}><button className="noticiaGrande" ><img src={Noticia3.capa} alt="notícia"/><p>{Noticia3.titulo}: {Noticia3.subtitulo}</p></button></a>  
                    <a href={Noticia4.slug}><button className="noticiaMedia1" ><img src={Noticia4.capa} alt="notícia"/><p>{Noticia4.titulo}: {Noticia4.subtitulo}</p></button></a>  
                    <a href={Noticia5.slug}><button className="noticiaMedia2" ><img src={Noticia5.capa} alt="notícia"/><p>{Noticia5.titulo}: {Noticia5.subtitulo}</p></button></a>  
                    <a href={Noticia6.slug}><button className="noticiaMedia3" ><img src={Noticia6.capa} alt="notícia"/><p>{Noticia6.titulo}: {Noticia6.subtitulo}</p></button></a>
                    <a href={Noticia7.slug}><button className="noticiaMedia4" ><img src={Noticia7.capa} alt="notícia"/><p>{Noticia7.titulo}: {Noticia7.subtitulo}</p></button></a>
               
                </div>

                <div id="videos" className="videos" hidden>
                    <iframe id="videoPrincipal" width="" height="" 
                    src={video1} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share fullscreen" allowfullscreen></iframe>
                

                    <div className="videoSeletor">
                        <button id="9" onClick={(e) => OptSelec(e.target.id)}><iframe width="" height="" src={video1} title="seletor" ></iframe></button>
                        <button id="10" onClick={(e) => OptSelec(e.target.id)}><iframe width="" height="" src={video2} title="seletor"></iframe></button>
                        <button id="11" onClick={(e) => OptSelec(e.target.id)}><iframe width="" height="" src={video3} title="seletor"></iframe></button> 
                        <button id="12" onClick={(e) => OptSelec(e.target.id)}><iframe width="" height="" src={video4} title="seletor"></iframe></button>                             
                    </div>   
                </div>
                
            </div>
        </>
  );
}

export default Noticias;
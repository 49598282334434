import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './pages/home/Home';
import Municipio from './pages/municipio/Municipio';
import Noticias from './pages/noticias/Noticias';
import Noticia from './pages/noticia/Noticia';
import Pessoa from './pages/pessoa/Pessoa';
import Secretaria from './pages/secretaria/Secretaria';
import Page404 from './pages/404/404';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Router>
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/municipio' element={<Municipio/>}/>
            <Route path='/noticias' element={<Noticias/>}/>
            <Route path='/noticia' element={<Noticia/>}/>
            <Route path='/pessoa/:slug' element={<Pessoa/>}/>
            <Route path='/secretaria/:slug' element={<Secretaria/>}/>
            <Route path='*' element={<Page404/>}/>
        </Routes>
      </Router>
     
  
  </React.StrictMode>
);




import React, { useEffect, useState } from "react";
import './footer.css';
import '../Geral/geral.css';

import logo3 from '../Geral/ooka.png';
import carregando from '../Geral/loading.gif';

import Facebook from '../../img/redes/Facebook.png';
import Instagram from '../../img/redes/Instagram.png';
import Twitter from '../../img/redes/Twitter.png';
import Tiktok from '../../img/redes/Tiktok.png';
import Youtube from '../../img/redes/Youtube.png';
import Telegram from '../../img/redes/Telegram.png';
import Whatsapp from '../../img/redes/Whatsapp.png';
import Linkedin from '../../img/redes/Linkedin.png';

import {base_url, midia_url} from '../../environment'

var titulo1 = 'Links';
var titulo2 = 'Outros';

var url = base_url+'/public';
var caminhoGlobal = midia_url;
var linksFooter = base_url+'/public/links/footer';
var linkAcaoPadrao = "_blank";

function Footer() {

    const [endereco, setEndereco] = useState('')
    const [fone, setFone] = useState('')
    const [email, setEmail] = useState('')
    const [expediente, setExpediente] = useState('')
    useEffect(() => {
        fetch(url)
        .then((d) => d.json())
        .then(dados => {
            setEndereco(dados.endereco)
            //console.log(dados.endereco)
            setFone("+55"+ dados.fone)
            //console.log(dados.fone)
            setEmail(dados.email)
            //console.log(dados.email)
            setExpediente(dados.expediente)
            //console.log(dados.expediente)
        });
    });

    var foneUrl = "tel:" + fone;
    var emailUrl = "mailto:" + email;

    // useEffect imagem logo
    useEffect(() => {
        fetch(url)
        .then((d) => d.json())
        .then(dados => {            
            document.getElementById("logo2").src = caminhoGlobal+dados.logo; 

            if(dados.facebook != null){document.getElementById("1").href = dados.facebook; document.getElementById("1").target = "_blank"}
            if(dados.instagram != null){document.getElementById("2").href = dados.instagram; document.getElementById("2").target = "_blank"}
            if(dados.twitter != null){document.getElementById("3").href = dados.twitter; document.getElementById("3").target = "_blank"}
            if(dados.tiktok != null){document.getElementById("4").href = dados.tiktok; document.getElementById("4").target = "_blank"}
            if(dados.youtube != null){document.getElementById("5").href = dados.youtube; document.getElementById("5").target = "_blank"}
            if(dados.telegram != null){document.getElementById("6").href = dados.telegram; document.getElementById("6").target = "_blank"}
            if(dados.whatsapp != null){document.getElementById("7").href = dados.whatsapp; document.getElementById("7").target = "_blank"}
            if(dados.linkedin != null){document.getElementById("8").href = dados.linkedin; document.getElementById("8").target = "_blank"}

        });
    });

    // useEffect links do footer
    useEffect(() => {
        fetch(linksFooter)
        .then((d) => d.json())
        .then(dados => {            

            var urlFinal;

            document.getElementById("primeiroLink").innerHTML = String('<p>'+titulo1+'</p><br/>');
           
            for(let i=0; i<dados.length; i++){

                if(dados[i].link == null){
                    urlFinal = dados[i].uri;
                    linkAcaoPadrao = "_self";
                }
                else
                {urlFinal = dados[i].link;}
                               
                document.getElementById("primeiroLink").innerHTML = document.getElementById("primeiroLink").innerHTML + 
                String('<a target='+linkAcaoPadrao+' href='+urlFinal+'><p>'+dados[i].titulo+'</p></a>');  

                linkAcaoPadrao = "_blank";
            }

            document.getElementById("segundoLink").innerHTML = String('<p>'+titulo2+'</p><br/>');
           
            for(let i=0; i<dados.length; i++){

                if(dados[i].link == null){
                    urlFinal = dados[i].uri;
                    linkAcaoPadrao = "_self";
                }
                else
                {urlFinal = dados[i].link;}
                               
                document.getElementById("segundoLink").innerHTML = document.getElementById("segundoLink").innerHTML + 
                String('<a target='+linkAcaoPadrao+' href='+urlFinal+'><p>'+dados[i].titulo+'</p></a>');  

                linkAcaoPadrao = "_blank";
            }

        });
    });

    return (
        <>       
            <div className="footer">
                <div id="footerA" className="footerA" >
                    <li>
                        <div className="logo2"><img id="logo2" className="loading" src={carregando} alt="banner2"/></div>
                        <br/>
                        <div className="footerInfoCardA">
                            <p>{endereco} <br/> {expediente}</p>
                            <p>Endereço</p>
                        </div>   
                        <div className="footerInfoCardB">
                            <p><a href={foneUrl}>{fone}</a><br/><a href={emailUrl}>{email}</a></p>
                            <p>Fale conosco</p>
                        </div>     
                    </li>
                    <div className="logo3"><img id="logo3" src={logo3} alt="fabricante"/></div>
               
                </div>    
                <div id="footerB" className="footerB">
                    <div className="footerRedes">

                        <p>Redes sociais </p>
                        <p>Redes sociais ( &uarr; para mais)</p>
                        <div className="redes">
                            <a href="/" id="2" ><button><img src={Instagram} alt="Instagram"/></button></a>
                            <a href="/" id="7" ><button><img src={Whatsapp} alt="Whatsapp"/></button></a>                            
                            <a href="/" id="5" ><button><img src={Youtube} alt="Youtube"/></button></a>
                            <a href="/" id="1" ><button><img src={Facebook} alt="Facebook"/></button></a>                            
                            <a href="/" id="3" ><button><img src={Twitter} alt="Twitter"/></button></a>                            
                            <a href="/" id="8" ><button><img src={Linkedin} alt="Linkedin"/></button> </a>
                            <a href="/" id="4" ><button><img src={Tiktok} alt="Tiktok"/></button></a>
                            <a href="/" id="6" ><button><img src={Telegram} alt="Telegram"/></button></a>
                        </div> 
                    </div>  
                    <div className="footerLinks">
                        <li id="primeiroLink" >
                            <p>{titulo1}</p>
                            <br/>
                            <p>Link 1</p>
                            <p>Link 2</p>
                            <p>Link 3</p>
                            <p>Link 4</p>
                            <p>Link 5</p>
                        </li>
                    </div>  
                    <div className="footerLinks">
                        <li id="segundoLink">
                            <p>{titulo2}</p>
                            <br/>
                            <p>Link 1</p>
                            <p>Link 2</p>
                            <p>Link 3</p>
                            <p>Link 4</p>
                            <p>Link 5</p>
                        </li>
                    </div>
                    
                </div>  
                
            </div>    
           
        </>
  );
}

export default Footer;
import React, { useEffect, useState } from "react";
import './local.css';
import '../../../components/Geral/geral.css';
import Carrousel from  '../../../components/Galeria/Galeria'
import carregando from '../../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../../environment';

const images = [{original: carregando,},];
var caminhoGlobal = midia_url;

var url = base_url+'/public';
var galerias = base_url+'/public/galerias';
var urlLocal;

function Local() {

     // useEffect verificar local
    const [local, setLocal] = useState('')
    useEffect(() => {
        fetch(url)
        .then((d) => d.json())
        .then(dados => {
            setLocal(dados.nome)
            //console.log(dados.nome)

            // eslint-disable-next-line
            if(dados.tipo == "PREFEITURA"){
                urlLocal = base_url+'/public/municipio';
            }
        });
    });

    // useEffect galeria
     useEffect(() => {
        fetch(galerias)
        .then((d) => d.json())
        .then(dados => {
          
            images.pop();
            //images.push({original: 'https://picsum.photos/id/700/1000/600/',},);
            //images.push({original: 'https://picsum.photos/id/1012/1000/600/',},);
            
            for(let i=0; i<dados.length; i++){    
                      
                images.push({original: caminhoGlobal+dados[i].capa,},);
            }
        });
    });

    //useEffect texto do local
     useEffect(() => {
        fetch(urlLocal)
        .then((d) => d.json())
        .then(dados => {
            console.log(dados)
            document.getElementById("localTexto").innerHTML = dados.sobre;
            //images[0].original = "https://picsum.photos/id/700/1000/600/";
        });
    });    

    return (
        <>       
            <div className="local">
                <div className="localInfo">
                    <p>{local}<br/></p>
                    <p id="localTexto" >Carregando...</p>
                </div>   
                <div className="localGaleria">

                    <Carrousel image={images}/>
                </div>       
            </div>  

            <div>
                
            </div>
            
        </>
  );
}

export default Local;
import React, { useEffect } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import Carrousel from  '../../components/Galeria/Galeria'
import carregando from '../../components/Geral/loading.gif';
import '../noticia/Noticia.css'
import {base_url, midia_url} from '../../environment'

var url = base_url+'/public/noticias';
var urlSecretarias = base_url+'/public/prefeitura/secretarias';
var caminhoGlobal = midia_url;
var linkAcaoPadrao = "_self";

const images = [{original: carregando,},];
var umaVez = true, orgao_id;
var secretarias= [];

function Noticia(){
    
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const slug = params.get('slug');
    var retorno = localStorage.getItem("secSelecionada");    

    orgao_id = params.get('orgao_id'); 

    //fltro das relacionadas lateral
    // eslint-disable-next-line
    if(retorno != 'null'){

        url = base_url+"/public/noticias?orgao_id="+retorno;
    } 

    // pegar info da secretaria pro botao 
    useEffect(() => {
        fetch(urlSecretarias)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 

            var qtdSecretarias = dados.length;
                      
            // eslint-disable-next-line
            if(retorno != 'null'){
                
                for(let i=0; i<qtdSecretarias; i++){
                    // eslint-disable-next-line
                    if(dados[i].id == retorno)
                    {
                        //qualSecretaria = dados[i].nome.substring(13);
                        //console.log(dados[i].nome.substring(13)); 
                        document.getElementById("selecionada").innerHTML = dados[i].nome.substring(13)+'  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#10006';                
                        document.getElementById("maisNoticias").href = "noticias?orgao_id="+orgao_id;
                    }                
                }
            }   
            else
            {
                document.getElementById("selecionada").innerHTML = 'Geral  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#10006';    
            }      
            
            for(let i=0; i<qtdSecretarias; i++){

                //guarda dados das secretarias
                secretarias.push(dados[i]);
            }

            //console.log(secretarias); 

        });
    });

    var urlEspecifica = base_url+'/public/noticias/' + slug;
    
    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }
   
    // pegar dados da noticia principal
    useEffect(() => {
        fetch(urlEspecifica)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 

            document.getElementById("titulo").innerHTML = dados.titulo; 

            var divGeral = document.getElementById("destaque");            

            images.pop();            
            for(let i=0; i<dados.Imagem.length; i++){    
                      
                images.push({original: caminhoGlobal+dados.Imagem[i].imagem,},);
            }
                        
            const noticiasInfo = document.createElement("p");
            let dataCorrigida = new Date(dados.data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            noticiasInfo.innerHTML = "Publicada em "+ dataCorrigida;
            noticiasInfo.className = "noticiasInfoEspecifica";

            const noticiasInfo2 = document.createElement("p");
            noticiasInfo2.innerHTML = "Visualizações: "+dados.views;  
            noticiasInfo2.className = "noticiasInfoEspecifica";

            
            const textoNoticiaEsp = document.createElement("p");
            textoNoticiaEsp.innerHTML = dados.conteudo;  
            textoNoticiaEsp.className = "textoNoticiaEspecifica";


            if(umaVez)
            {
                divGeral.appendChild(noticiasInfo); 
                divGeral.appendChild(noticiasInfo2);                 
                divGeral.appendChild(textoNoticiaEsp); 
                umaVez = false;
            }
            
               
        });
    });

    // pegar dados das noticias
    useEffect(() => {
        fetch(url)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 

            var arrayInicial = [];
            var origemNoticia = '#';
            var mostraSecretaria;
            
            for(let i=0; i<3; i++){

                if(dados[i] == null)
                {
                    break;
                }

                mostraSecretaria = null;
                
                if(dados[i].Orgao != null)
                {
                    origemNoticia = "noticia?slug="+dados[i].slug+"&orgao_id="+dados[i].Orgao.id;

                    //console.log(secretarias)
                    var index = -1;
                    var val = String(dados[i].Orgao.id)
                    //console.log(val)
                    // eslint-disable-next-line
                    secretarias.find(function(item, i){if(String(item.id) === val)index = i;});
                    //console.log(index)
                    mostraSecretaria = secretarias[index].nome.substring(13);
                    //console.log(mostraSecretaria);
                }
                else
                {
                    origemNoticia = "noticia?slug="+dados[i].slug;
                    mostraSecretaria = "Geral";
                }
                
                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<a target='+linkAcaoPadrao+ ' href='+origemNoticia+'><button><img src='+caminhoGlobal+dados[i].capa+' alt="Noticia"/><p>'+dados[i].titulo+': '+dados[i].subtitulo+'</p><p>'+mostraSecretaria+'</p></button></a>')
                );
            }
            
            document.getElementById("inicial").innerHTML = arrayInicial;                   
        });
    });   
    

    return(
        <>   
            <Header />
                <a href="noticias" target="_self"><button className="selecionada" id="selecionada" >Carregando...  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#10006;</button></a>
                <p className="titulo" id="titulo"></p>
                <div className="noticiaEspecifica">
                    <div className="noticiaEspecificaInfo" id="secretariasInfo">
                        <div id='destaque'> <Carrousel image={images}/></div>    
                        <div id='inicial' >{loading()}</div>
                    </div>   
                    <a href="noticias" target="_self" id="maisNoticias" ><button className="maisNoticias" >Mais notícias</button></a>
                </div>
            <Footer/>
        </>   
    );
}

export default Noticia;
import React from "react";
import './intermediario.css';
import '../../../components/Geral/geral.css';

function Intermediario() {
    return (
        <>       
            <div className="intermediario">
                <li>
                    <button>Portal</button>
                    <button>Ouvidoria</button>
                    <button>ESIC</button>
                </li> 
            </div>
        </>
  );
}

export default Intermediario;
import '../components/style.css'

function CarrouselSimbolo(props) {
  return (
      <div className="cardSimbolos">       
            <img src={"https://api2.ooka.com.br"+ props.img} alt="imagem" />             
      </div>
); 
}

export default CarrouselSimbolo;

import React, { useEffect} from "react";
import './servidores.css';
import '../../../components/Geral/geral.css';
import carregando from '../../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../../environment';

var administracao = base_url+'/public/administracao';
var caminhoGlobal = midia_url;
var umavez = true;

function Servidores() {

    // useEffect pegar pessoas
    useEffect(() => {
        fetch(administracao)
        .then((d) => d.json())
        .then(dados => {  
                        
            if(umavez)
            {

                const list = document.getElementById("servidores");
                list.removeChild(list.lastElementChild);

                for(let i=0; i<dados.length; i++){

                    const node = document.createElement("div");      
                    node.className = "servidoresCard";

                    const img = document.createElement("img");
                    img.className = "img";
                    img.src = caminhoGlobal+dados[i].foto;
                    img.alt = dados[i].nome;

                    const paragrafo = document.createElement("p");
                    paragrafo.className = "texto";
                    paragrafo.innerHTML = dados[i].nome;
                    
                    const link = document.createElement("a");
                    link.href = "pessoa/"+dados[i].slug;
                    link.target = "_self";
                    
                    link.appendChild(node);
                    node.appendChild(img);
                    node.appendChild(paragrafo);                    
                    document.getElementById("servidores").appendChild(link);    

                }  
                umavez = false;
            }                      
        });
    });


    return (
        <>       
            <div className="servidores" id="servidores">
                <div className="servidoresCard"><img className="img" src={carregando} alt="pessoa"/><p className="texto" >Carregando...</p></div>     
            </div>  
        </>
  );
}

export default Servidores; 